<template>
	<div class="row">
		<div class="col-12">
			<div class="card">
				<div class="card-header"></div>
				<b-table
					striped
					hover
					selectable
					responsive
					show-empty
					:items="tableData"
					:fields="fields"
					:current-page="currentPage"
					:busy="loading"
					>
					<template #table-busy>
						<div
							class="
                d-flex
                flex-column
                justify-content-center
                align-items-center
              "
							>
							<div class="spinner-border" role="status"></div>
							<p class="text-center mt-2"><strong>Loading...</strong></p>
						</div>
					</template>

					<template #empty>
						<p class="text-center text-secondary">No records available</p>
					</template>
					<template #cell(serialNumber)="data">
						<p>
							{{ data.index + 1 }}
						</p>
					</template>

					<template #cell(vehicle_type)="data">
						<div class="d-flex align-items-center">
							<div class="avatar avatar-xs bus-avatar">
								<img
									src="@/assets/img/gray_bus.svg"
									class="avatar-img"
									alt="Bus"
									/>
							</div>
							<p class="ml-2 mt-2 mb-2">
								{{ data.item.typeName }}
							</p>
						</div>
					</template>

					<template #cell(description)="data">
						<p>{{ data.item.description }}</p>
					</template>

					<template #cell(pricePerTrip)="data">
						<div v-if="editRow === data.index">
							<div class="form-group d-flex flex-wrap mb-0">
								<div class="col-6 col-lg-3 pl-0">
									<input
										type="number"
										class="form-control form-control-sm"
										v-model.number="editPrice"
										/>
								</div>
								<div class="col-auto px-0">
									<button
										class="btn btn-sm btn-primary"
										:disabled="savingUpdate"
										@click="saveUpdate"
										>
										{{ savingUpdate ? 'Saving...' : 'Save' }}
									</button>
								</div>
								<div class="col-auto pl-0">
									<button
										class="btn btn-sm cancel-btn"
										:disabled="savingUpdate"
										@click="cancelUpdate"
										>
										Cancel
									</button>
								</div>
							</div>
						</div>

						<span v-else>
							<span v-if="data.item.costPerTrip !== null">
								&#8358; {{ data.item.costPerTrip || '' }}</span
								>
							<span v-else>N/A</span>
						</span>
					</template>

					<template #cell(action)="data">
						<button
							class="btn btn-sm edit-btn"
							v-if="editRow !== data.index"
							:disabled="savingUpdate"
							@click="edit(data)"
							>
							Edit
						</button>
					</template>
				</b-table>
			</div>
		</div>
	</div>
</template>

<script>
import tableView from '@/mixins/tableview'
import { lookupService } from '@/services/lookup'
import { extractErrorMessage } from '@/utils/helpers'
export default {
  name: 'PartnerCostConfiguration',
  mixins: [tableView],
  props: {
    partnerId: {
      type: [Number, String],
      required: true
    }
  },
  data () {
    return {
      pageSize: 10,
      currentPage: 1,
      totalRecords: 0,
      loading: false,
      errorLoading: false,
      partner_id: null,
      city_id: null,
      tableData: [],
      fields: [
        {
          key: 'serialNumber',
          label: 'S/N'
        },
        {
          key: 'vehicle_type',
          label: 'Vehicle Category'
        },
        {
          key: 'description',
          label: 'Description'
        },
        {
          key: 'pricePerTrip',
          label: 'Price Per Trip'
        },
        {
          key: 'action',
          label: 'Action'
        }
      ],
      showAddVehicleModal: false,
      editRow: -1,
      savingUpdate: false,
      editPrice: null
    }
  },
  created () {
    this.fetchConfigurationData()
    this.getPartnerConfiguration()
  },
  methods: {
    async fetchConfigurationData () {
      try {
        this.loading = true

        const categories = await lookupService
          .fetchVehicleCategories()
          .then((res) => {
            return res.data.data || []
          })

        const configs = (await this.getPartnerConfiguration()) || []

        const configMap = new Map()

        for (const config of configs) {
          configMap.set(`${config.vehicle_type.name}`, config)
        }

        const updatedTableData = []
        categories.forEach((vehicleCategory) => {
          const key = `${vehicleCategory.name}`
          if (configMap.has(key)) {
            const config = configMap.get(key)
            this.partner_id = config.partner_id
            this.city_id = config.city_id
            updatedTableData.push({
              id: config.id,
              partnerId: config.partner_id,
              vehicleType: key,
              typeName: config.vehicle_type.name,
              costPerTrip: config.cost_of_supply,
              cityId: config.city_id,
              vehicleId: vehicleCategory.id,
              description: vehicleCategory.description
            })
          } else {
            updatedTableData.push({
              id: null,
              partnerId: this.partner_id,
              vehicleType: key,
              typeName: vehicleCategory.name,
              costPerTrip: null,
              cityId: this.city_id,
              vehicleId: vehicleCategory.id,
              description: vehicleCategory.description
            })
          }
        })

        this.tableData = updatedTableData
      } catch (e) {
        const errorMessage = extractErrorMessage(
          e,
          'An error occurred while trying to load the page data.'
        )
        this.$toastr.e(errorMessage, 'Failed to load data')
      } finally {
        this.loading = false
      }
    },
    /**
     * @returns Array
     */
    async getPartnerConfiguration () {
      return await this.axios
        .get(
          `v1/partners/${this.partnerId}/vehicle-type-cost-of-supply?related=vehicle_type`
        )
        .then((res) => {
          return res.data.data || []
        })
    },

    edit (data) {
      this.editRow = data.index
      this.editPrice = data.item.costPerTrip || null
    },

    async updateCostConfiguration (config) {
      const isEdit = !!config.id
      if (isEdit) {
        const updated = await this.axios.patch(
          `/v1/partners/${this.partnerId}/vehicle-type-cost-of-supply/${config.id}`,
          {
            cost_of_supply: config.costPerTrip
          }
        )
        return updated
      } else {
        return await this.axios.post(
          `v1/partners/${this.partnerId}/vehicle-type-cost-of-supply`,
          {
            vehicle_type_id: config.vehicleId,
            cost_of_supply: config.costPerTrip,
            city_id: Number(config.cityId)
          }
        )
      }
    },

    async saveUpdate () {
      const data = this.tableData[this.editRow]
      data.costPerTrip = this.editPrice
      this.savingChange = true
      this.savingUpdate = true
      try {
        await this.updateCostConfiguration(data)
        this.$toastr.s('Vehicle cost was updated successfully', 'Success')
        await this.fetchConfigurationData()
      } catch (error) {
        const errorMessage = extractErrorMessage(
          error,
          'An error occurred. Please try again later'
        )
        this.$toastr.e(errorMessage, 'Error')
      } finally {
        this.savingUpdate = false
      }
      this.editRow = -1
      this.savingUpdate = false
    },

    cancelUpdate () {
      this.editRow = -1
    }
  }
}
</script>

<style lang="scss" scoped>
@use 'src/assets/scss/b-table';

.edit-btn {
  background: #4848ed;
  color: #ffffff;
}

.cancel-btn {
  background: transparent;
  color: #101211;
}
</style>
